*,
html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: "HiraKaku";
  src: url("../../static/fonts/HiraKakuPro-W3.otf") format("truetype");
}

.scroll-container {
  position: fixed;
  will-change: transform;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.zalo-chat-widget {
  right: 18px !important;
  bottom: 103px !important;
}

.ant-modal-content {
  padding: 0 !important;
  border-radius: 0 !important;
}

.custom-modal .ant-modal-content {
  background-color: unset;
  box-shadow: none;
  background-clip: unset;
}

.contactus {
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 2000ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 2000ms;
}
.grecaptcha-badge {
  visibility: hidden;
}
.react-pdf__Page__svg {
  width: 1080px !important;
  height: 1510px !important;
}
.react-pdf__Page__svg > svg {
  width: 1080px !important;
  height: 1510px !important;
}
.react-pdf__Page__annotations {
  height: 0 !important;
}
.react-pdf__Document {
  margin: 10px 0;
}
